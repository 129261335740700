import React from 'react';
import { RangeRenderer } from './RangeRenderer';
import { InfoCircleOutlined } from '@ant-design/icons';
import { cx } from '@emotion/css';
import { useGridClassNames } from '../OrderResult.styles.ts';
import { Filter } from '../filter/FilterTypes.ts';
import { Markable } from '../../../components/Markable.tsx';
import type { LabResultObservationResult } from '../../../generated/openapi/befunde-api';

export const ObservationResultRenderer: React.FC<{
  result: LabResultObservationResult;
  index: number;
  filter: Filter;
  deemphasizePathological: boolean;
}> = ({ result, index, filter, deemphasizePathological }) => {
  const {
    gridClassName,
    entryBorderClassName,
    emphasizedClassName,
    accreditationClassName,
    parameterClassName,
    resultClassName,
    pathologicalClassName,
    unitClassName,
    rangeClassName,
    graphicClassName,
    popupGraphicClassName,
    commentClassName,
  } = useGridClassNames();
  const emphasize = !!result.pathological && !deemphasizePathological;
  const hasComments = result.comment.length > 0;
  return (
    <>
      <div className={cx(gridClassName(index), hasComments ? '' : entryBorderClassName)}>
        <div className={accreditationClassName}>{result.accreditation}</div>
        <div className={cx(parameterClassName, emphasize && emphasizedClassName)}>
          <Markable tokens={filter.tokens}>{result.testName}</Markable>
        </div>
        <div className={cx(resultClassName, emphasize && emphasizedClassName)}>{result.measuredValue}</div>
        <div className={cx(pathologicalClassName, emphasize ? emphasizedClassName : '')}>{result.pathological}</div>
        <div className={unitClassName}>{result.unit}</div>
        <div className={rangeClassName}>{result.standardRange}</div>
        <div className={graphicClassName}>
          <RangeRenderer
            result={result}
            width="200px"
            height="12px"
            deemphasizePathological={deemphasizePathological}
            type="normal"
          />
        </div>
        <div className={popupGraphicClassName}>
          <RangeRenderer
            result={result}
            width="200px"
            height="12px"
            deemphasizePathological={deemphasizePathological}
            type="popup"
          />
        </div>
      </div>

      {hasComments ? (
        <div className={cx(gridClassName(index), entryBorderClassName)}>
          <div className={commentClassName}>
            <InfoCircleOutlined />
            <pre>{result.comment.join('\n')}</pre>
          </div>
        </div>
      ) : null}
    </>
  );
};
