import { FC } from 'react';
import { css } from '@emotion/css';
import { theme, Typography } from 'antd';
import { FemaleIcon } from '../../icons/FemaleIcon.tsx';
import { MaleIcon } from '../../icons/MaleIcon.tsx';
import type { LabResultPatient, LabResultsSearchResult } from '../../generated/openapi/befunde-api';
import { formatDateString } from '../../utils/datetime.ts';

const usePatientInfoClassNames = () => {
  const { token } = theme.useToken();

  return {
    name: css`
      line-height: 1.1em;
      margin-bottom: 0;

      @media (max-width: ${token.screenLG}px) {
        margin-top: ${token.marginMD}px;
      }
    `,
    additional: css`
      margin-bottom: ${token.margin}px;
    `,
    svnr: css`
      font-size: ${token.fontSizeSM}px;
    `,
  };
};

export const GenderIcon: FC<{ gender: string | null; className?: string }> = ({ gender, className }) => {
  switch (gender) {
    case 'M':
      return <MaleIcon className={className} />;
    case 'W':
      return <FemaleIcon className={className} />;
  }
  return null;
};

export const patientFullName = (patient: LabResultPatient | LabResultsSearchResult) => {
  const titleLastName = `${patient.title} ${patient.lastName}`.trim();
  return [titleLastName, patient.firstName].join(', ').trim();
};

export const PatientDateAndSvnr: FC<{
  className?: string | undefined;
  patient: LabResultPatient | LabResultsSearchResult;
  svnrCopyable?: boolean;
}> = ({ className, patient, svnrCopyable }) => {
  const { svnr } = usePatientInfoClassNames();
  const { token } = theme.useToken();

  return (
    <div className={className}>
      <GenderIcon
        gender={patient.gender}
        className={css`
          margin-right: ${token.marginXS}px;
        `}
      />
      {formatDateString(patient.birthdate)}
      <Typography.Text type="secondary" className={svnr}>
        {' - SVNR: '}
      </Typography.Text>
      <Typography.Text copyable={svnrCopyable}>{patient.svnr}</Typography.Text>
    </div>
  );
};

export const PatientInfo: FC<{
  patient: LabResultPatient;
}> = ({ patient }) => {
  const { name, additional } = usePatientInfoClassNames();
  return (
    <>
      <h1 className={name}>{patientFullName(patient)}</h1>
      <PatientDateAndSvnr className={additional} patient={patient} svnrCopyable />
    </>
  );
};
