import React from 'react';
import { css } from '@emotion/css';
import { Button, Empty, theme } from 'antd';
import { Link } from 'react-router';
import { LeftOutlined } from '@ant-design/icons';
import { PatientInfo } from '../orderresult/PatientInfo.tsx';
import { useLabResult } from '../../api/lab-result.queries.ts';
import { OrderResultRenderer } from '../orderresult/OrderResultRenderer.tsx';

const useBefundDetailsClassNames = (isSelected: boolean) => {
  const { token } = theme.useToken();

  return {
    layout: css`
      height: 100%;
      padding-left: ${token.paddingXL}px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      @media (max-width: ${token.screenLG}px) {
        height: calc(100dvh - 80px);
        position: absolute;
        left: 0;
        right: 0;
        padding: 0 ${token.paddingLG}px 0 ${token.paddingLG}px;
        transform: translateX(${isSelected ? 0 : '100vw'});
        transition: transform 0.4s ease-in-out;

        background-color: ${token.Layout?.bodyBg};
        z-index: 10;
      }
    `,
    empty: css`
      flex-grow: 1;

      @media (max-width: ${token.screenLG}px) {
        display: none;
      }
    `,
    backButton: css`
      @media (min-width: ${token.screenLG}px) {
        display: none;
      }
    `,
  };
};

export const BefundDetails: React.FC<{
  selectedId: string | undefined;
  deemphasizePathological?: boolean;
}> = ({ selectedId, deemphasizePathological }) => {
  const { layout, empty, backButton } = useBefundDetailsClassNames(!!selectedId);
  const { data: result } = useLabResult({ id: selectedId || '' });
  // TODO loading, error state

  console.log(deemphasizePathological);

  return (
    <div className={layout}>
      {!selectedId ? (
        <Empty
          className={empty}
          description="Bitte wählen Sie einen Befund aus"
          image={Empty.PRESENTED_IMAGE_DEFAULT}
        />
      ) : (
        <>
          <Link to="/" className={backButton}>
            <Button icon={<LeftOutlined />}>Zurück zur Übersicht</Button>
          </Link>
          {result ? (
            <>
              <PatientInfo patient={result?.patient} />
              <OrderResultRenderer result={result} deemphasizePathological={deemphasizePathological || false} />
            </>
          ) : null}
        </>
      )}
    </div>
  );
};
