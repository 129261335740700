import React, { useEffect, useMemo, useState } from 'react';
import { Alert, Button, Flex, Input, List, Spin, theme, Tooltip } from 'antd';
import { css, cx } from '@emotion/css';
import { InfoCircleOutlined, SearchOutlined, WarningTwoTone } from '@ant-design/icons';
import { BEFUNDE_LIST } from '../../utils/elementIds.ts';
import { Link } from 'react-router';
import { useSearchLabResults } from '../../api/lab-result.queries.ts';
import { useDebounce } from 'use-debounce';
import { patientFullName } from '../orderresult/PatientInfo.tsx';
import type { LabResultsSearchResult } from '../../generated/openapi/befunde-api';
import { Markable } from '../../components/Markable.tsx';
import { useKeyAction } from '../../hooks/useKeyAction.ts';
import dayjs from 'dayjs';

const DEFAULT_PAGE_SIZE = 100;

const useBefundeListClassNames = () => {
  const { token } = theme.useToken();

  return {
    layout: css`
      height: 100%;
      width: 100%;
      gap: ${token.paddingLG}px;
      padding-bottom: ${token.paddingLG}px;

      @media (min-width: ${token.screenLG}px) {
        width: 350px;
      }

      @media (min-width: ${token.screenXXL}px) {
        width: 400px;
      }
    `,
    befundListWrapper: css`
      height: 100%;
      overflow-y: auto;
      width: 100%;
      border-right: 1px solid ${token.colorBorder};
      padding-right: ${token.paddingXS}px;

      @media (max-width: ${token.screenLG}px) {
        border-right: none;
        padding-right: 0;
      }
    `,
    befundListItem: (selected: boolean) =>
      cx(
        css`
          border-radius: ${token.borderRadius}px;
          border-block-end: none !important;
          .ant-list &.ant-list-item {
            padding: ${token.padding}px ${token.paddingXS}px ${token.padding}px ${token.paddingXS}px;
          }
          .ant-list &.ant-list-item .ant-list-item-meta .ant-list-item-meta-title {
            margin-bottom: 0;
          }
        `,
        {
          [css`
            background-color: ${token.controlItemBgActiveHover};
          `]: selected,
        }
      ),
    leftRight: css`
      display: flex;
      flex-direction: row;
      gap: ${token.paddingXS}px;
      align-items: center;
    `,
    name: css`
      font-weight: bold;
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `,
    orderSvnr: css`
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `,
    rightItem: css`
      align-content: end;
      color: ${token.colorTextDescription};
      font-weight: lighter;
    `,
  };
};

const SearchField: React.FC<{
  value: string;
  onChange: (value: string) => void;
}> = ({ value, onChange }) => {
  const [internalValue, setInternalValue] = useState(value);
  const [debouncedSearch] = useDebounce(internalValue, 500);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (value !== debouncedSearch) {
      onChange(debouncedSearch);
    }
  }, [value, debouncedSearch, onChange]);

  const action = useMemo(() => {
    if (focused) {
      return () => {
        setInternalValue('');
      };
    } else {
      return null;
    }
  }, [focused]);

  useKeyAction('Escape', action);

  return (
    <Input
      allowClear
      autoFocus
      size="large"
      placeholder="Name, SVNR, Auftragsnummer ..."
      value={internalValue || ''}
      onChange={e => setInternalValue(e.target.value)}
      prefix={<SearchOutlined />}
      suffix={
        <Tooltip title="Suche Befunde nach Name, SVNR, Auftragsnummer">
          <InfoCircleOutlined />
        </Tooltip>
      }
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
    />
  );
};

type Pageable = {
  page: number;
  pageSize: number;
};

type PagedBefundeListState = {
  search: string;
  searchTokens: string[];
  pageable: Pageable;
  loading: boolean;
  error: boolean;
  loadedItems: LabResultsSearchResult[];
  hasMore: boolean;
};

const DEFAULT_PAGEABLE: Pageable = {
  page: 1,
  pageSize: DEFAULT_PAGE_SIZE,
};

export const BefundeList: React.FC<{ selectedId: string | undefined }> = ({ selectedId }) => {
  const styles = useBefundeListClassNames();
  const [state, setState] = useState<PagedBefundeListState>({
    search: '',
    searchTokens: [],
    loading: true,
    error: false,
    pageable: DEFAULT_PAGEABLE,
    loadedItems: [],
    hasMore: false,
  });

  const {
    data: labResults,
    isLoading: labResultsLoading,
    error: labResultsError,
  } = useSearchLabResults({
    page: state.pageable.page,
    pageSize: state.pageable.pageSize,
    search: state.search,
  });

  useEffect(() => {
    if (!labResultsLoading) {
      setState(prev => {
        const updated = new Map(prev.loadedItems.map(e => [e.id, e]));
        (labResults?.items || []).forEach(labResult => {
          updated.set(labResult.id, labResult);
        });
        return {
          ...prev,
          loading: false,
          errorAtPage: !!labResultsError,
          loadedItems: Array.of(...updated.values()),
          hasMore: labResults?.items?.length === DEFAULT_PAGE_SIZE,
        };
      });
    }
  }, [labResults, setState, labResultsLoading, labResultsError]);

  return (
    <Flex vertical className={styles.layout}>
      <SearchField
        value={state.search}
        onChange={value => {
          setState({
            search: value.toLowerCase(),
            searchTokens: value.toLowerCase().split(' '),
            pageable: DEFAULT_PAGEABLE,
            loading: true,
            error: false,
            loadedItems: [],
            hasMore: false,
          });
        }}
      />
      <div id={BEFUNDE_LIST} className={styles.befundListWrapper}>
        <PagedBefundeList
          state={state}
          selectedId={selectedId}
          onNextPage={() => {
            setState(prev => {
              return {
                ...prev,
                loading: true,
                error: false,
                pageable: { ...prev.pageable, page: prev.pageable.page + 1 },
                hasMore: false,
              };
            });
          }}
        />
      </div>
    </Flex>
  );
};

const formatTimestamp = (timestamp: string) => {
  const ts = dayjs(timestamp);
  const now = dayjs();
  if (ts.day() === now.day() && ts.month() === now.month() && ts.year() === now.year()) {
    return ts.format('HH:mm');
  }
  if (ts.day() === now.day() - 1 && ts.month() === now.month() && ts.year() === now.year()) {
    return 'Gestern';
  }
  return ts.format('DD.MM.YYYY');
};

const PagedBefundeList: React.FC<{
  state: PagedBefundeListState;
  selectedId: string | undefined;
  onNextPage: () => void;
}> = ({ state, selectedId, onNextPage }) => {
  const { token } = theme.useToken();
  const styles = useBefundeListClassNames();

  return (
    <>
      {state.loadedItems?.length > 0 && (
        <List
          dataSource={state.loadedItems}
          renderItem={item => (
            <Link to={`/${item.id}`}>
              <List.Item key={item.id} className={styles.befundListItem(item.id === selectedId)}>
                <List.Item.Meta
                  title={
                    <div className={styles.leftRight}>
                      <div className={styles.name}>
                        <Markable tokens={state.searchTokens}>{patientFullName(item)}</Markable>
                      </div>
                      <div className={styles.rightItem}>
                        {item.countPathological > 0 ? (
                          <span>
                            <WarningTwoTone twoToneColor="orange" /> {item.countPathological}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  }
                  description={
                    <div className={styles.leftRight}>
                      <div className={styles.orderSvnr}>
                        <Markable tokens={state.searchTokens}>{item.orderNumber}</Markable>
                        {' ('}
                        <Markable tokens={state.searchTokens}>{item.svnr}</Markable>
                        {')'}
                      </div>
                      <div className={styles.rightItem}>{formatTimestamp(item.timestamp)}</div>
                    </div>
                  }
                />
              </List.Item>
            </Link>
          )}
        />
      )}

      {state.loading && (
        <Flex justify={'center'}>
          <div>
            <Spin style={{ marginRight: `${token.margin}px` }} />
            Lade ...
          </div>
        </Flex>
      )}

      {state.error && <Alert type="warning" message="Fehler beim Laden" />}

      {state.hasMore && (
        <Button type="link" onClick={() => onNextPage()}>
          Weitere Befunde laden
        </Button>
      )}
    </>
  );
};
