/* tslint:disable */
/* eslint-disable */
/**
 * Befunde API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { LabResultResponse } from '../model';
// @ts-ignore
import type { LabResultsSearchResponse } from '../model';
// @ts-ignore
import type { RestError } from '../model';
/**
 * LabResultApi - axios parameter creator
 * @export
 */
export const LabResultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary LabResult Detail
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResult: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('labResult', 'id', id)
            const localVarPath = `/lab-result/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary LabResults Search
         * @param {number} page 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchLabResults: async (page: number, pageSize: number, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('searchLabResults', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('searchLabResults', 'pageSize', pageSize)
            const localVarPath = `/lab-result`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LabResultApi - functional programming interface
 * @export
 */
export const LabResultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LabResultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary LabResult Detail
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labResult(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LabResultResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labResult(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LabResultApi.labResult']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary LabResults Search
         * @param {number} page 
         * @param {number} pageSize 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchLabResults(page: number, pageSize: number, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LabResultsSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchLabResults(page, pageSize, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LabResultApi.searchLabResults']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LabResultApi - factory interface
 * @export
 */
export const LabResultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LabResultApiFp(configuration)
    return {
        /**
         * 
         * @summary LabResult Detail
         * @param {LabResultApiLabResultRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResult(requestParameters: LabResultApiLabResultRequest, options?: RawAxiosRequestConfig): AxiosPromise<LabResultResponse> {
            return localVarFp.labResult(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary LabResults Search
         * @param {LabResultApiSearchLabResultsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchLabResults(requestParameters: LabResultApiSearchLabResultsRequest, options?: RawAxiosRequestConfig): AxiosPromise<LabResultsSearchResponse> {
            return localVarFp.searchLabResults(requestParameters.page, requestParameters.pageSize, requestParameters.search, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for labResult operation in LabResultApi.
 * @export
 * @interface LabResultApiLabResultRequest
 */
export interface LabResultApiLabResultRequest {
    /**
     * 
     * @type {string}
     * @memberof LabResultApiLabResult
     */
    readonly id: string
}

/**
 * Request parameters for searchLabResults operation in LabResultApi.
 * @export
 * @interface LabResultApiSearchLabResultsRequest
 */
export interface LabResultApiSearchLabResultsRequest {
    /**
     * 
     * @type {number}
     * @memberof LabResultApiSearchLabResults
     */
    readonly page: number

    /**
     * 
     * @type {number}
     * @memberof LabResultApiSearchLabResults
     */
    readonly pageSize: number

    /**
     * 
     * @type {string}
     * @memberof LabResultApiSearchLabResults
     */
    readonly search?: string
}

/**
 * LabResultApi - object-oriented interface
 * @export
 * @class LabResultApi
 * @extends {BaseAPI}
 */
export class LabResultApi extends BaseAPI {
    /**
     * 
     * @summary LabResult Detail
     * @param {LabResultApiLabResultRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabResultApi
     */
    public labResult(requestParameters: LabResultApiLabResultRequest, options?: RawAxiosRequestConfig) {
        return LabResultApiFp(this.configuration).labResult(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary LabResults Search
     * @param {LabResultApiSearchLabResultsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabResultApi
     */
    public searchLabResults(requestParameters: LabResultApiSearchLabResultsRequest, options?: RawAxiosRequestConfig) {
        return LabResultApiFp(this.configuration).searchLabResults(requestParameters.page, requestParameters.pageSize, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }
}

