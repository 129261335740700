import React from 'react';
import { useRequestRendererClassNames } from './useRequestRendererClassNames.ts';
import { formatDateTimeString } from '../../../utils/datetime.ts';
import { LabResultObservationRequestStatusEnum, LabResultResponse } from '../../../generated/openapi/befunde-api';
import { Flex, theme } from 'antd';

const translateLabResultState = (value: LabResultObservationRequestStatusEnum) => {
  switch (value) {
    case 'IN_PROGRESS':
      return 'Befund in Arbeit';
    case 'PARTIAL_RESULT':
      return 'Teilbefund';
    case 'END_RESULT':
      return 'Endergebnis';
    case 'ORDER_RECEIVED':
      return 'Auftrag empfangen';
    case 'SPECIMEN_RECEIVED':
      return 'Probe empfangen';
  }
  return value;
};

export const RequestRenderer: React.FC<{ result: LabResultResponse }> = ({ result }) => {
  const { token } = theme.useToken();
  const { subheaderClassName } = useRequestRendererClassNames();

  return (
    <Flex wrap gap={token.paddingLG}>
      <div>
        <div className={subheaderClassName}>Auftragsnr.</div>
        <div>{result.orderNumber}</div>
      </div>
      <div>
        <div className={subheaderClassName}>Eingangsdatum</div>
        <div>{formatDateTimeString(result.observationRequest.orderEntryDate)}</div>
      </div>
      <div>
        <div className={subheaderClassName}>Status</div>
        <div>{translateLabResultState(result.observationRequest.status)}</div>
      </div>
      <div>
        <div className={subheaderClassName}>Stand</div>
        <div>{formatDateTimeString(result.timestamp)}</div>
      </div>
    </Flex>
  );
};
